<template>
  <div class="absolute inset-0 object-cover w-full h-full">
    <NuxtImg v-if="size !== 'desktop' && backgroundImageMobile && backgroundImageMobile.length"
             :src="backgroundImageMobile"
             alt=""
             class="object-cover w-full h-full lg:hidden"
             sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
             preload
    />

    <video v-if="size !== 'desktop' && backgroundVideoMobile && backgroundVideoMobile.length"
           class="object-cover w-full h-full lg:hidden"
           playsinline
           loop
           autoplay
           muted
           disableremoteplayback="true"
           :src="backgroundVideoMobile"
    />

    <NuxtImg v-if="backgroundImage && backgroundImage.length"
             :src="backgroundImage"
             alt=""
             class="object-cover w-full h-full"
             sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
             preload
    />

    <video v-if="backgroundVideo && backgroundVideo.length"
           class="object-cover w-full h-full"
           playsinline
           loop
           autoplay
           muted
           :src="backgroundVideo"
    />
  </div>
</template>

<script setup lang="ts">
import { useDetectSize } from '../../../composables/detect-size';

const size = useDetectSize();

interface HeroBackgroundProps {
  backgroundImage?: string;
  backgroundImageMobile?: string;
  backgroundVideo?: string;
  backgroundVideoMobile?: string;
}

withDefaults(defineProps<HeroBackgroundProps>(), {
  backgroundImage: '',
  backgroundImageMobile: '',
  backgroundVideo: '',
  backgroundVideoMobile: ''
});
</script>
